/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useEffect, useState, useRef, Fragment, memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { InView } from "react-intersection-observer";

// Styles
import styles from "./styles.css";

// Hooks
import useScrollDirection from "../../../hooks/scroll-direction";
import useTdRateNowPopup from "../td-rate-now-popup/use-td-rate-now-popup";

// Components
import ArrowIcon from "../../shared/arrow";
import H2 from "../../shared/h2";
import LazyComponent from "../../shared/lazy-component";
import MetaTagsCarHome from "../../shared/meta-tags-car-home";
const HomeBannerSection = loadable(() => import("../home-banner-section"), { ssr: true });
const HomePageDynamicBanner = loadable(() => import("../home-page-dynamic-banner"), {ssr: true});
const ServiceInletsSection = loadable(() => import("../service-inlets"), { ssr: true });
const ExclusiveBannersSection = loadable(() => import("../exclusive-banners-section"), { ssr: true });

const FilterSuggestionByKey = loadable(() => import("../filter-suggestion-by-key"), { ssr: true });
const PopularCategory = loadable(() => import("../popular-category"), { ssr: true });
const FilterResumeSession = loadable(() => import("../filter-resume-session"), { ssr: false });
import PilotFaqV2 from "../pilot-faq-v2";
import SeoContent from "../home-page-seo-content";
const HomeCarSection = loadable(() => import("../home-car-section"), { ssr: false });
const CustomRecommendedCarSection = loadable(() => import("../custom-recommended-car-section"), { ssr: false });
const OfferBannerImageSlider = loadable(() => import("../offer-banner-image-slider"));
const ZeroDownpaymentLandingDetail = loadable(() => import("../zero-downpayment-landing-detail"));
const WhyToBuyLanding = loadable(() => import("../why-to-buy-landing"));
const HowToBuyVideoSteps = loadable(() => import("../how-to-buy-video-steps"));
const HomeSellCarSection = loadable(() => import("../home-sell-car-section"));
const YardInfoBanner = loadable(() => import("../yard-info-banner/component"));
const TdRateNowPopup = loadable(() => import("../td-rate-now-popup"));
const CarsUnderBudget = loadable(() => import("../car-under-budget"));

// Constants
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { COOKIE_CONSTANTS, NUMBER, HOME_CAR_SECTION } from "../../../constants/app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import { defaultFilterPayload, defaultParams } from "../car-listing/constants";

// Utilities
import parseCookie from "../../../utils/helpers/parse-cookie";
import debounce from "../../../utils/helpers/debounce";
import updateRecentlyViewedCookie from "../../../utils/helpers/update-recently-viewed-cookie";
import Filters from "../../../utils/filters-v2";
import deleteCookie from "../../../utils/helpers/delete-cookie";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import saveCookie from "../../../utils/helpers/save-cookie";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import DownloadAppNudge from "../download-app-nudge";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";

const MemoizedHomePageSection = memo(({
    selectedCity,
    isReturningUser = false,
    isShowResumeSessionSection = false,
    showMultiHubBanner,
    handleDownloadAppNudgeOnClickHandler,
    showDownloadNudge,
    seoContent,
    superAppConfig
}) => {

    useEffect(() => {
        trackPlatformCustomEventsAEV2(b2cHomePageEvents.homepageVisited);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div styleName={"styles.homeBannerSectionWrapper"}>
                <HomeBannerSection selectedCity={selectedCity} source={ROUTE_NAME.HOME}/>
            </div>
            {superAppConfig?.topBanner?.showTopBanner && <div styleName={"styles.dynamicBannerSectionWrapper"}>
                <HomePageDynamicBanner />
            </div>}
            <div styleName={"styles.serviceInletSectionWrapper"}>
                <ServiceInletsSection />
            </div>
            <div styleName={"styles.exclusiveOffersSectionWrapper"}>
                <ExclusiveBannersSection />
            </div>
            <div styleName={"styles.makeSectionWrapper"}>
                <div styleName={"styles.header"}>Cars for sale by brand</div>
                <FilterSuggestionByKey filterKey={FILTER_ENTITIES.MAKE} />
            </div>
            {isReturningUser && (
                <LazyComponent>
                    <div styleName={"styles.homeCarRecentlyViewedSectionWrapper"}>
                        <HomeCarSection sectionName={HOME_CAR_SECTION.RECENTLY_VIEWED} />
                    </div>
                </LazyComponent>
            )}
            {isShowResumeSessionSection && (
                <LazyComponent>
                    <div styleName={"styles.filterResumeSessionWrapper"}>
                        <FilterResumeSession />
                    </div>
                </LazyComponent>
            )}
            {(isReturningUser && isShowResumeSessionSection) && (
                <LazyComponent>
                    <div styleName={"styles.customRecommendedSectionWrapper "}>
                        <div styleName={"styles.sepratorBorder"} />
                        <CustomRecommendedCarSection />
                    </div>
                </LazyComponent>
            )}
            <div styleName={"styles.popularCategoryWrapper "}>
                <PopularCategory
                    selectedCity={selectedCity}
                    isShowResumeSessionSection={isShowResumeSessionSection}
                />
            </div>
            {(isReturningUser && !isShowResumeSessionSection) && (
                <LazyComponent>
                    <div styleName={"styles.customRecommendedSectionWrapper "}>
                        <div styleName={"styles.sepratorBorder"} />
                        <CustomRecommendedCarSection />
                    </div>
                </LazyComponent>
            )}
            <div styleName={"styles.bodyFilterSectionWrapper"}>
                <div styleName={"styles.bodyFilterWrap"}>
                    <FilterSuggestionByKey filterKey={FILTER_ENTITIES.BODY_TYPE} selectedCity={selectedCity} />
                </div>
            </div>
            {!isReturningUser && (
                <div styleName={"styles.homeCarRecommendedSectionWrapper"}>
                    <LazyComponent>
                        <HomeCarSection sectionName={HOME_CAR_SECTION.RECOMMENDED} />
                    </LazyComponent>
                </div>
            )}
            {!isReturningUser && (
                <div styleName={"styles.budgetFriendySectionWrapper"}>
                    <LazyComponent>
                        <div styleName={"styles.budgetFriendySection"}>
                            <FilterSuggestionByKey filterKey={FILTER_ENTITIES.PRICE} />
                        </div>
                    </LazyComponent>
                </div>
            )}
            <div styleName={"styles.homeSellCarSectionWrapper"}>
                <LazyComponent>
                    <HomeSellCarSection />
                </LazyComponent>
            </div>
            {showMultiHubBanner && <div styleName={"styles.yardSectionWrapper"}>
                <LazyComponent>
                    <YardInfoBanner />
                </LazyComponent>
            </div>}
            <div styleName={"styles.offerSectionWrapper"}>
                <LazyComponent>
                    <div styleName={"styles.offerSliderWrapper"}>
                        <span styleName={"styles.offerTitle"}>Exclusive offer</span>
                        <OfferBannerImageSlider page={ROUTE_NAME?.HOME} />
                    </div>
                </LazyComponent>
            </div>
            {isReturningUser && (
                <div styleName={"styles.homeCarRecentlyAddedSectionWrapper"}>
                    <LazyComponent>
                        <HomeCarSection sectionName={HOME_CAR_SECTION.RECENTLY_ADDED} />
                    </LazyComponent>
                </div>
            )}
            {isReturningUser && (
                <div styleName={"styles.budgetFriendySectionWrapper"}>
                    <LazyComponent>
                        <div styleName={"styles.budgetFriendySection"}>
                            <FilterSuggestionByKey filterKey={FILTER_ENTITIES.PRICE} />
                        </div>
                    </LazyComponent>
                </div>
            )}
            <div styleName={"styles.zerodpSectionWrapper"}>
                <LazyComponent>
                    <ZeroDownpaymentLandingDetail />
                </LazyComponent>
            </div>
            {seoContent && <SeoContent />}
            <div styleName={"styles.whyToBuySectionWrapper"}>
                <LazyComponent>
                    <div styleName={"styles.whyToBuyWrapHome"}>
                        <WhyToBuyLanding isHomePage={true} />
                    </div>
                </LazyComponent>
            </div>
            <div styleName={"styles.howToBuySectionWrapper"}>
                <LazyComponent>
                    <HowToBuyVideoSteps isHomePage={true} />
                </LazyComponent>
            </div>
            <div styleName={"styles.carsUnderBudgetWrapper"}>
                <LazyComponent>
                    <CarsUnderBudget />
                </LazyComponent>
            </div>
            {
                showDownloadNudge && <DownloadAppNudge onClickHandler={handleDownloadAppNudgeOnClickHandler} source={ROUTE_NAME.HOME} />
            }
        </Fragment>
    );
});

const Home = ({
    showBackToTop,
    setBackToTopConnect,
    selectedCity,
    isReturningUser = false,
    isShowFilterResumeSession = false,
    isFilterSSR = false,
    setIsReturningUserConnect,
    showMultiHubBanner,
    showDownloadNudge,
    updateDownloadNudgeStatusConnect,
    initializeTestInjectionReducerConnect,
    getTdNpsFeedbackInfoConnect,
    isLoggedIn,
    isUserDetailsFetched,
    secureToken,
    seoContent,
    superAppConfig,
    getStatsigDynamicConfigConnect,
    fetchWishlistedCarsListConnect,
    budgetSection,
    recentlyViewedCars,
    fetchRecentlyViewedCarsConnect,
    fetchSeoContentConnect
}) => {

    const history = useHistory();
    const timerId = useRef(undefined);
    const [faqInView, setFaqInView] = useState(false);
    const [isShowResumeSessionSection, setIsShowResumeSessionSection] = useState(isShowFilterResumeSession);
    const recentlyViewedCarsFromCookie = JSON.parse(parseCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS) || "[]")?.slice(NUMBER.ZERO, NUMBER.FIVE) || [];
    const isRecentlyViewedCarsPresent = recentlyViewedCars?.length !== NUMBER.ZERO || false;
    const { showTdPopup, tdOrderData } = useTdRateNowPopup({ getTdNpsFeedbackInfoConnect, isLoggedIn, isUserDetailsFetched, secureToken });
    const {
        code: selectedCityCode,
        name: selectedCityName
    } = selectedCity || {};

    const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });

    const fetchRecentlyViewedCars = () => {
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sf: `appointmentId:${recentlyViewedCarsFromCookie?.join(";")}` }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: recentlyViewedCarsFromCookie?.length
        };
        const { payload } = filters.getListingPayload({ ...filterPayload });
        fetchRecentlyViewedCarsConnect(params, payload).then(({ total, results }) => {
            // Handle edge case where recently viewed cars might get de-listed
            if (total < recentlyViewedCarsFromCookie?.length) {
                updateRecentlyViewedCookie(results);
            }
            // eslint-disable-next-line no-unused-vars
        }).catch((error) => { deleteCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS);});
    };

    useEffect(() => {
        if (recentlyViewedCarsFromCookie.length) {
            fetchRecentlyViewedCars();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // COMMENT BELOW LINE , to Check SSR and CSR cases
        initializeTestInjectionReducerConnect("HOME USE EFFECT");

        if (!isFilterSSR) {
            const isShowFilterResumeSessionCookieFlag = JSON.parse(parseCookie(COOKIE_CONSTANTS.SHOW_RESUME_FILTER_SESSION) || "false") || false;
            setIsShowResumeSessionSection(isShowFilterResumeSessionCookieFlag);
        }

        if (superAppConfig?.isDefaultConfig) {
            getStatsigDynamicConfigConnect("msite_super_app_homepage ");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!budgetSection?.items?.length) fetchSeoContentConnect("homepage");
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetSection]);

    useEffect(() => {
        if (showDownloadNudge) {
            saveCookie(COOKIE_CONSTANTS.FIRST_TIME_VISIT_IN_A_DAY, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDownloadNudge]);

    useEffect(() => {
        if (isLoggedIn && secureToken) {
            fetchWishlistedCarsListConnect(secureToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const handleHidingBackToTop = (scrollDirectionUp) => {
        if (showBackToTop && typeof window !== "undefined") {
            if (scrollDirectionUp && timerId.current) {
                window.clearTimeout(timerId.current);
            }
            timerId.current = window.setTimeout(setBackToTopConnect, NUMBER.SIX_THOUSAND, { show: false, top: NUMBER.EIGHTY });
        } else if (typeof window !== "undefined") {
            window.clearTimeout(timerId.current);
        }
    };

    const isTabNavTriggered = useScrollDirection({
        threshold: NUMBER.TEN,
        callback: debounce(handleHidingBackToTop, NUMBER.FIVE_HUNDRED)
    });

    const handleDownloadAppNudgeOnClickHandler = useCallback(async ({ ctaType }) => {
        await yieldToMainThread();
        updateDownloadNudgeStatusConnect(false);
        trackPlatformCustomEventsAEV2(b2cHomePageEvents.appDownloadBottomDrawer, { eventLabel: ctaType });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInViewChange = (inView) => {
        setFaqInView(inView);
    };

    const goToHelpCenter = () => {
        trackPlatformCustomEventsAEV2(b2cHomePageEvents.homePageFaqViewAll);
        history.push("/help-center?source=ae.home");
    };

    useEffect(() => {
        setIsReturningUserConnect(isRecentlyViewedCarsPresent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecentlyViewedCarsPresent]);

    useEffect(() => {
        handleHidingBackToTop(isTabNavTriggered);
        return () => {
            if (timerId.current && typeof window !== "undefined") {
                window.clearTimeout(timerId.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBackToTop, isTabNavTriggered]);

    useEffect(() => {
        if (isTabNavTriggered && faqInView) {
            trackPlatformCustomEventsAEV2(b2cHomePageEvents.b2cBackToTopShownHomePage);
            setBackToTopConnect({ show: true });
        } else {
            setBackToTopConnect({ show: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faqInView, isTabNavTriggered]);

    return (
        <div>
            <MetaTagsCarHome />
            <MemoizedHomePageSection
                selectedCity={selectedCity}
                isReturningUser={isReturningUser}
                isShowResumeSessionSection={isShowResumeSessionSection}
                showMultiHubBanner={showMultiHubBanner}
                showDownloadNudge={showDownloadNudge}
                handleDownloadAppNudgeOnClickHandler={handleDownloadAppNudgeOnClickHandler}
                seoContent={seoContent}
                superAppConfig={superAppConfig}
            />
            <InView onChange={handleInViewChange} />
            <div styleName={"styles.faqWrapper"}>
                <span styleName={"styles.viewAllCta"} onClick={goToHelpCenter}>View All <ArrowIcon /></span>
                <PilotFaqV2
                    headingComponent={H2}
                    heading="FAQs"
                    attachSchema={false}
                    pushEvent={true}
                />
            </div>
            {showTdPopup && <TdRateNowPopup tdOrderData={tdOrderData} />}
        </div>
    );
};

Home.propTypes = {
    setBackToTopConnect: PropTypes.func,
    showBackToTop: PropTypes.bool,
    selectedCity: PropTypes.object,
    isReturningUser: PropTypes.bool,
    isShowFilterResumeSession: PropTypes.bool,
    isFilterSSR: PropTypes.bool,
    setIsReturningUserConnect: PropTypes.func,
    showMultiHubBanner: PropTypes.bool,
    showDownloadNudge: PropTypes.bool,
    updateDownloadNudgeStatusConnect: PropTypes.func,
    initializeTestInjectionReducerConnect: PropTypes.func,
    getTdNpsFeedbackInfoConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    isUserDetailsFetched: PropTypes.bool,
    secureToken: PropTypes.string,
    seoContent: PropTypes.string,
    getStatsigDynamicConfigConnect: PropTypes.func,
    superAppConfig: PropTypes.object,
    fetchWishlistedCarsListConnect: PropTypes.object,
    fetchSeoContentConnect: PropTypes.func,
    budgetSection: PropTypes.object,
    fetchRecentlyViewedCarsConnect: PropTypes.func,
    recentlyViewedCars: PropTypes.array
};

MemoizedHomePageSection.propTypes = {
    isReturningUser: PropTypes.bool,
    isShowResumeSessionSection: PropTypes.bool,
    selectedCity: PropTypes.object,
    showMultiHubBanner: PropTypes.bool,
    showDownloadAppNudgeModal: PropTypes.bool,
    handleDownloadAppNudgeOnClickHandler: PropTypes.func,
    showDownloadNudge: PropTypes.bool,
    seoContent: PropTypes.string,
    superAppConfig: PropTypes.object
};

export default Home;
