import { ListingService } from "../../../service";
import Types from "../config-ssr/types";

const updateSeoData = (data) => ({
    type: Types.UPDATE_SEO_DATA,
    data
});

const fetchSeoContent = (pageType = "") => (dispatch)  => {
    ListingService.fetchSeoContent(pageType).then(response => {
        dispatch(updateSeoData(response?.data));
    });
};

export {
    fetchSeoContent
};
