/* eslint-disable max-statements, complexity */
import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";

//Images
import IconCarsLogo from "./images/cars-logo.js";
import IconAlohaBrowser from "./images/aloha.webp";
import IconBrave from "./images/brave.webp";
import IconDuckDuckGo from "./images/duckduckgo.webp";
import IconFirefox from "./images/firefox.webp";
import IconChrome from "./images/google_chrome.webp";
import IconMicrosoftEdge from "./images/microsoft_edge.webp";
import IconOpera from "./images/opera.webp";
import IconSamsungInternetBrowser from "./images/samsung_internet_browser.webp";
import IconTorBrowser from "./images/tor_browser.webp";
import IconVivaldi from "./images/vivaldi.webp";
import IconSafari from "./images/safari.webp";
import IconTorBrowserIOS from "./images/tor_browser_ios.png";

// import Modal from "../../shared/modal";
import { COACH_DOWNLOAD_APP_URL, NUMBER } from "../../../constants/app-constants";
import { browserNames } from "./constant";
import { getUserOs } from "../../../utils/helpers/device-type";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { ROUTE_NAME } from "../../../constants/routes-constants";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events.js";

const DownloadAppNudge = ({ onClickHandler, source }) => {

    const [browerInfo, setBrowserInfo] = useState({ browserIcon: null, browserName: "" });
    const [loading, setLoading] = useState(false);
    const trackingSource = {
        [ROUTE_NAME.LISTING]: "listing",
        [ROUTE_NAME.HOME]: "home"
    };

    const handleBrowserInfo = async () => {
        await yieldToMainThread();
        if (window !== undefined && window?.navigator) {
            const browser = window?.navigator?.userAgent?.toLowerCase();
            const userOs = getUserOs();
            if (browser.indexOf("chrome") > -1) {
                setBrowserInfo({
                    browserIcon: IconChrome,
                    browserName: browserNames.google_chrome
                });
            } else if (browser.indexOf("/alohabrowser/i") > -1) {
                setBrowserInfo({
                    browserIcon: IconAlohaBrowser,
                    browserName: browserNames.aloha_browser
                });
            } else if (browser.indexOf("brave") > -1) {
                setBrowserInfo({
                    browserIcon: IconBrave,
                    browserName: browserNames.brave
                });
            } else if (browser.indexOf("duckduckgo") > -1) {
                setBrowserInfo({
                    browserIcon: IconDuckDuckGo,
                    browserName: browserNames.duckduckgo_private_browser
                });
            } else if (browser.indexOf("firefox") > -1) {
                setBrowserInfo({
                    browserIcon: IconFirefox,
                    browserName: browserNames.firefox
                });
            } else if (browser.indexOf("edg") > -1) {
                setBrowserInfo({
                    browserIcon: IconMicrosoftEdge,
                    browserName: browserNames.microsoft_edge
                });
            } else if (browser.indexOf("opr") > -1) {
                setBrowserInfo({
                    browserIcon: IconOpera,
                    browserName: browserNames.opera_browser
                });
            } else if (browser.indexOf("torbrowser") > -1) {
                setBrowserInfo({
                    browserIcon: userOs === "msite_android" ? IconTorBrowser : IconTorBrowserIOS,
                    browserName: browserNames.tor_browser
                });
            } else if (browser.indexOf("vivaldi") > -1) {
                setBrowserInfo({
                    browserIcon: IconVivaldi,
                    browserName: browserNames.vivaldi_browser
                });
            } else if (browser.indexOf("safari") > -1) {
                setBrowserInfo({
                    browserIcon: IconSafari,
                    browserName: browserNames.safari
                });
            } else if (browser.indexOf("samsungbrowser") > -1) {
                setBrowserInfo({
                    browserIcon: IconSamsungInternetBrowser,
                    browserName: browserNames.samsung_internet_browser
                });
            }
        }
    };

    useEffect(() => {
        handleBrowserInfo();
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.openAppDownloadBottomDrawer, { eventLabel: trackingSource[source] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = async (e) => {
        e.stopPropagation();
        setLoading(true);
        await yieldToMainThread();
        window.setTimeout(() => {
            onClickHandler({ ctaType: "open" });
            window.location.href = COACH_DOWNLOAD_APP_URL;
            setLoading(false);
        }, NUMBER.THOUSAND);
    };

    const handleContinueClick = async (e) => {
        e.stopPropagation();
        await yieldToMainThread();
        onClickHandler({ ctaType: "continue" });
    };

    const handleOutsideClick = async (e) => {
        e.stopPropagation();
        await yieldToMainThread();
    };

    const { browserIcon, browserName } = browerInfo;

    return (
        <div styleName={"styles.customeModal"} onClick={handleOutsideClick}>
            <div styleName={"styles.wrapper"}>
                <div styleName={"styles.headerWrap"}>
                    <h2>See CARS24 in...</h2>
                </div>
                <div styleName={"styles.bodyWrap"}>
                    <div styleName={"styles.flexRow"}>
                        <IconCarsLogo />
                        <p>CARS24 App</p>
                        <span styleName={"styles.openCta"} >
                            <p styleName={"styles.font-color-white"}
                                onClick={handleClick}
                            >
                                Open
                                {loading && <i className="spinner" />}
                            </p>
                        </span>
                    </div>
                    <div styleName={"styles.flexRow"}>
                        {browserIcon && <img src={browserIcon} alt="" width={20} height={20} />}
                        {browserName && <p>{browserName}</p>}
                        <span styleName={"styles.continueCta"} onClick={handleContinueClick}>Continue</span>
                    </div>
                </div>
            </div>
        </div>

    );
};

DownloadAppNudge.propTypes = {
    onClickHandler: PropTypes.func,
    source: PropTypes.string
};
export default memo(DownloadAppNudge);
