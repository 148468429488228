import * as React from "react";
const Cars24Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#FF870F"
      d="M22.768.064H1.228C.902.064.59.197.36.434.13.67 0 .993 0 1.328v9.333c0 .337.129.66.359.899.23.239.542.374.869.377h21.54c.677 0 1.232-.565 1.232-1.248V1.357C24 .63 23.457.064 22.768.064Zm-6.18 11.333H1.228a.702.702 0 0 1-.5-.219.746.746 0 0 1-.204-.517V1.328A.744.744 0 0 1 .73.815a.702.702 0 0 1 .498-.212h15.36v10.794Zm6.889-1.23-1.173 1.23h-1.622l2.795-2.905v1.674Zm0-2.605h-.707l-3.703 3.835h-1.62l3.704-3.835h-1.616l-2.424 2.513V8.428l.827-.866h-.827V.603h5.657c.395 0 .709.33.709.754v6.205Z"
    />
    <path
      fill="#0032A6"
      d="M3.232 10.293c1.1 0 1.535-.609 1.535-1.623v-.767h-1.02v.994c0 .286-.127.441-.51.441-.37 0-.51-.155-.51-.441v-5.8c0-.286.14-.44.51-.44.383 0 .51.154.51.44v1h1.02v-.772c0-1.015-.435-1.623-1.535-1.623-1.088 0-1.524.608-1.524 1.623V8.67c0 1.014.436 1.623 1.524 1.623ZM6.239 8.528h1.065l.196 1.647h.996l-1.134-8.35H6.18l-1.134 8.35h.995l.197-1.647Zm.532-4.784L7.2 7.562h-.857l.428-3.818ZM9.92 6.54h.353c.382 0 .639.173.639.662v1.492c0 .728.021 1.112.31 1.481h.926a2.326 2.326 0 0 1-.217-1.016V7.274c0-.549-.087-.907-.69-1.086.487-.107.608-.477.608-.942V2.87c0-.632-.418-1.046-1.043-1.046H8.9v8.35h1.02V6.54Zm0-3.75h.677c.174 0 .232.045.232.224v2.35c0 .18-.058.238-.232.238H9.92V2.79ZM13.915 10.293c.972 0 1.46-.62 1.46-1.456v-1.17c0-.488-.126-.858-.416-1.216l-1.194-1.54c-.197-.25-.29-.465-.29-.823v-.99c0-.287.142-.442.454-.442.313 0 .455.155.455.442V4.21h1.02V3.157c0-.835-.497-1.455-1.47-1.455-.972 0-1.48.62-1.48 1.455v1.17c0 .489.13.859.408 1.217l1.218 1.54c.197.25.304.465.304.823v.99c0 .286-.156.441-.469.441-.312 0-.468-.155-.468-.441V7.789h-.992v1.048c0 .836.488 1.456 1.46 1.456M19.928 4.16c.234-.495.364-.85.364-1.25 0-.775-.327-1.087-.832-1.087-.406 0-.83.286-.83 1.11h.535c0-.398.092-.609.301-.609.216 0 .29.204.29.61 0 .28-.034.527-.292 1.06l-.865 1.848v.499h1.709V5.83h-1.162l.782-1.67Z"
    />
    <path
      fill="#0032A6"
      d="M21.713 1.881h-.504l-1.04 2.894v.487h1.02V6.34h.524V5.26h.276v-.51h-.276V1.88Zm-.524 2.87h-.528l.528-1.487V4.75Z"
    />
  </svg>
);
export default Cars24Logo;
