import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useToggle from "../../../hooks/use-toggle";
import styles from "./styles.css";

const SeoContent = ({ seoContent, fetchSeoContentConnect }) => {
    const [showAllText, setShowAllText] = useToggle(false);
    useEffect(() => {
        if (!seoContent) {
            fetchSeoContentConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div styleName={"styles.seoContent"}>
            <div styleName={`${showAllText ? "styles.showAllText" : ""}`} dangerouslySetInnerHTML={{ __html: seoContent }} />
            <span styleName={`styles.readMore ${showAllText ? "styles.readLess" : ""}`} onClick={setShowAllText}>
                {showAllText ? "READ LESS" : "READ MORE"}
            </span>
        </div>
    );
};
SeoContent.propTypes = {
    seoContent: PropTypes.string,
    fetchSeoContentConnect: PropTypes.func
};
export default SeoContent;
