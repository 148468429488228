import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Home from "./component";
import { setBackToTop } from "../back-to-top/actions";
import { fetchRecentlyViewedCars, setIsReturningUser } from "../filters/actions";
import { updateDownloadNudgeStatus } from "../../ae.configuration/actions";
import { initializeTestInjectionReducer } from "../../shared/testing-injection/actions";
import { getTdNpsFeedbackInfo } from "../post-booking-confirmation-v2/actions";
import { getStatsigDynamicConfig } from "../config-ssr/actions";
import { fetchWishlistedCarsList } from "../my-wishlist/actions";
import { fetchSeoContent } from "./actions";

const mapStateToProps = ({
    backToTop: {
        show: showBackToTop
    },
    config: {
        activeSales,
        showMultiHubBanner,
        seoContent,
        superAppConfig,
        budgetSection
    } = {},
    user: {
        showDownloadNudge
    },
    cities: {
        selectedCity
    },
    filters: {
        isReturningUser,
        isShowFilterResumeSession,
        isSSR: isFilterSSR,
        customRecommendedCars,
        recentlyViewedCars = []
    },
    config: {
        activeSaleConfig
    },
    user: {
        isLoggedIn, isFetched: isUserDetailsFetched, secureToken
    }

}) => ({
    showBackToTop,
    selectedCity,
    activeSales,
    isReturningUser,
    isShowFilterResumeSession,
    isFilterSSR,
    showMultiHubBanner,
    activeSaleConfig,
    isLoggedIn,
    isUserDetailsFetched,
    secureToken,
    showDownloadNudge,
    seoContent,
    superAppConfig,
    budgetSection,
    customRecommendedCars,
    recentlyViewedCars
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setBackToTopConnect: setBackToTop,
    setIsReturningUserConnect: setIsReturningUser,
    updateDownloadNudgeStatusConnect: updateDownloadNudgeStatus,
    initializeTestInjectionReducerConnect: initializeTestInjectionReducer,
    getTdNpsFeedbackInfoConnect: getTdNpsFeedbackInfo,
    getStatsigDynamicConfigConnect: getStatsigDynamicConfig,
    fetchWishlistedCarsListConnect: fetchWishlistedCarsList,
    fetchSeoContentConnect: fetchSeoContent,
    fetchRecentlyViewedCarsConnect: fetchRecentlyViewedCars
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
