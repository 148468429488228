import React from "react";

// Styles
import styles from "./styles.css";

// Components
import HomePage from "../../../components/ae.mobile/home";
import withAELayout from "../../../components/ae.mobile/layout";
import ErrorHandler from "../../../components/shared/with-error-handler";

const Home = () => {
    return (
        <ErrorHandler>
            <HomePage />
        </ErrorHandler>
    );
};

export default withAELayout(Home, true, true, { headerProps: { showBlueIcon: false, showCall: false, showBuySellCta: true, showWishlist: true, wishlistIconColor: "white" } });
