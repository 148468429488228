import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getItem, setItem } from "../../../utils/helpers/storage-crud";
import { LOCALSTORAGE_CONSTANTS } from "../../../constants/app-constants";

const useTdRateNowPopup = ({
    getTdNpsFeedbackInfoConnect,
    isLoggedIn,
    isUserDetailsFetched,
    secureToken
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [orderData, setOrderData] = useState(null);

    useEffect(() => {
        if (isLoggedIn && isUserDetailsFetched) {
            getTdNpsFeedbackInfoConnect(secureToken)
                .then((resp) => {
                    setOrderData(resp);
                    const { orderId } = resp || {};

                    const prevViewedOrderId = getItem(LOCALSTORAGE_CONSTANTS.TD_RATE_NOW_POPUP_ORDER_ID);

                    if (orderId && prevViewedOrderId !== orderId) {
                        setShowPopup(true);
                        setItem(orderId, LOCALSTORAGE_CONSTANTS.TD_RATE_NOW_POPUP_ORDER_ID);
                    }
                })
                .catch(() => {});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTdNpsFeedbackInfoConnect, isLoggedIn, secureToken]);

    return { showTdPopup: showPopup, tdOrderData: orderData };
};

useTdRateNowPopup.propTypes = {
    getTdNpsFeedbackInfoConnect: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isUserDetailsFetched: PropTypes.bool.isRequired,
    secureToken: PropTypes.string.isRequired
};

export default useTdRateNowPopup;
