import Types from "./types";

const setBackToTop = (payload) => ({
    type: Types.SET_BACK_TO_TOP,
    payload
});

export {
    setBackToTop
};
